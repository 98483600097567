<template>
  <div class="view">
    <header class="view__header">
      <p class="text">View</p>
      <IconButton
        class="button--outline-grey button--md button--brd-right-0"
        :class="{ 'button--selected': type === 'card' }"
        @click="type = 'card'"
      >
        <IconViewCards></IconViewCards>
      </IconButton>
      <IconButton
        class="button--outline-grey button--md button--brd-left-0"
        :class="{ 'button--selected': type === 'list' }"
        @click="type = 'list'"
      >
        <IconListView></IconListView>
      </IconButton>
    </header>
  </div>
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconListView from "./icons/IconListView.vue";
import IconViewCards from "./icons/IconViewCards.vue";
export default {
  components: {
    IconButton,
    IconListView,
    IconViewCards,
  },
  data() {
    return {
      type: "list",
    };
  },
  watch: {
    type(val) {
      this.$emit("input", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.view {
  margin-left: 2rem;
  .text {
    @include type($fw: 700);
    color: $black;
    margin-right: 2rem;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
  }
}
</style>