var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopUp',{attrs:{"show":_vm.modal},on:{"hideModal":_vm.hideModal}},[_c('ValidationObserver',{ref:"observer",staticClass:"pressrelease",attrs:{"tag":"div","id":"scroll-block"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('header',{staticClass:"pressrelease__header"},[_c('h3',{staticClass:"pressrelease__header_title"},[_vm._v(" "+_vm._s(_vm.pressrelease.id ? "Edit" : "Add")+" Press Release ")]),_c('div',{staticClass:"pressrelease__header_actions"},[(_vm.item)?_c('BaseButton',{staticClass:"button--sm button--outline button--uppercase",attrs:{"text":"Delete"},on:{"click":function($event){return _vm.$emit('removeItem', _vm.pressrelease)}}}):_vm._e(),_c('BaseButton',{staticClass:"button--sm button--green button--uppercase",attrs:{"text":"Save Changes","loading":_vm.changesLoading,"disabled":invalid || _vm.pressrelease.header_image === '' || !_vm.changed},on:{"click":_vm.savePressRelease}})],1)]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Press Release Information")]),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Title","required":true,"validateError":errors[0] || _vm.serverError.title,"element":_vm.pressrelease.title},on:{"input":function($event){_vm.pressrelease.title = $event}},model:{value:(_vm.pressrelease.title),callback:function ($$v) {_vm.$set(_vm.pressrelease, "title", $$v)},expression:"pressrelease.title"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Author","required":true,"validateError":errors[0],"element":_vm.pressrelease.author},on:{"input":function($event){_vm.pressrelease.author = $event}},model:{value:(_vm.pressrelease.author),callback:function ($$v) {_vm.$set(_vm.pressrelease, "author", $$v)},expression:"pressrelease.author"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{attrs:{"placeholder":"Date Published","time":false,"required":true,"endDate":_vm.today,"validateError":errors[0],"value":_vm.pressrelease.date_published},on:{"input":function($event){_vm.pressrelease.date_published = $event}},model:{value:(_vm.pressrelease.date_published),callback:function ($$v) {_vm.$set(_vm.pressrelease, "date_published", $$v)},expression:"pressrelease.date_published"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row form__row--align-start"},[_c('div',{staticClass:"form__row_col form__row_col--side"},[_c('BaseSelect',{attrs:{"placeholder":"Status","type":"status","selected":_vm.pressrelease.status,"items":[
              { id: 0, title: 'Draft' },
              { id: 1, title: 'Published' } ]},on:{"input":function($event){_vm.pressrelease.status = $event.id}},model:{value:(_vm.pressrelease.status),callback:function ($$v) {_vm.$set(_vm.pressrelease, "status", $$v)},expression:"pressrelease.status"}})],1),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, path: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Path","required":true,"validateError":errors[0] || _vm.serverError.slug,"element":_vm.pressrelease.slug},on:{"input":function($event){_vm.pressrelease.slug = $event}},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('span',{staticClass:"form-control__simple-text"},[_vm._v("Example: "+_vm._s((_vm.url + "/press-releases/" + (_vm.pressrelease.slug || "path"))))])]},proxy:true}],null,true),model:{value:(_vm.pressrelease.slug),callback:function ($$v) {_vm.$set(_vm.pressrelease, "slug", $$v)},expression:"pressrelease.slug"}})]}}],null,true)})],1)]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Press Release Details")]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col"},[_c('Editor',{attrs:{"placeholder":"Text Editor","value":_vm.pressrelease.details},on:{"input":function($event){_vm.pressrelease.details = $event}},model:{value:(_vm.pressrelease.details),callback:function ($$v) {_vm.$set(_vm.pressrelease, "details", $$v)},expression:"pressrelease.details"}})],1)]),_c('div',{staticClass:"form__row"},[_c('MultipleAutocompleteInput',{attrs:{"id":"autocomplete-tag","focusOpen":true,"loading":_vm.tagsLoading,"items":_vm.pressrelease.tags,"matches":_vm.matches,"placeholder":"Tags"},on:{"addItem":_vm.addTag,"input":function($event){_vm.pressrelease.tags = $event},"searchMatches":_vm.apiGetMatchedTags},model:{value:(_vm.pressrelease.tags),callback:function ($$v) {_vm.$set(_vm.pressrelease, "tags", $$v)},expression:"pressrelease.tags"}})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Button Text","validateError":errors[0],"element":_vm.pressrelease.button_text},on:{"input":function($event){_vm.pressrelease.button_text = $event}},model:{value:(_vm.pressrelease.button_text),callback:function ($$v) {_vm.$set(_vm.pressrelease, "button_text", $$v)},expression:"pressrelease.button_text"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Button URL","validateError":errors[0],"element":_vm.pressrelease.button_url},on:{"input":function($event){_vm.pressrelease.button_url = $event}},model:{value:(_vm.pressrelease.button_url),callback:function ($$v) {_vm.$set(_vm.pressrelease, "button_url", $$v)},expression:"pressrelease.button_url"}})]}}],null,true)})],1)]),_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v("Images")]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col form__row_col--side form__row_col--bordered"},[_c('span',{staticClass:"form__row_label"},[_vm._v("Search Image")]),_c('DragAndDropImage',{staticClass:"drag-and-drop--full-width",attrs:{"id":"search","size":"15","image":_vm.pressrelease.search_image,"v-model":_vm.pressrelease.search_image},on:{"input":function($event){_vm.pressrelease.search_image = $event.image;
              $event.removed ? _vm.removedImages.push($event.removed) : false;},"removeImage":function($event){_vm.removedImages.push($event);
              _vm.pressrelease.search_image = '';}}})],1),_c('div',{staticClass:"form__row_col form__row_col--side form__row_col--bordered"},[_c('span',{staticClass:"form__row_label"},[_vm._v("Header Image "),_c('span',{staticClass:"form__row_label_required"},[_vm._v("*")])]),_c('DragAndDropImage',{staticClass:"drag-and-drop--full-width",attrs:{"id":"header","size":"15","image":_vm.pressrelease.header_image,"v-model":_vm.pressrelease.header_image},on:{"input":function($event){_vm.pressrelease.header_image = $event.image;
              $event.removed ? _vm.removedImages.push($event.removed) : false;},"removeImage":function($event){_vm.removedImages.push($event);
              _vm.pressrelease.header_image = '';}}})],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }